$max-width: rem(900) !global;
$logo-width: rem(150);
$logo-height: rem(70);
$nav-padding-top: rem(5);
$nav-height: $nav-padding-top * 2 + $logo-height;
$search-box-height: $nav-height + rem(400);
$footer-height: rem(280);
$copy-height: rem(32);

html,
body,
h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}

body {
    font-family: $helvetica;
    font-size: rem(16);
    padding-bottom: $footer-height + $copy-height;
}

input,
select,
option,
textarea {
    font-size: rem(16);
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

hr {
    border: 0;
    height: 1px;
    margin: rem(50);
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0)
    );
}

.no-margin {
    margin: 0px;
}

.right {
    text-align: right;
}

.top {
    vertical-align: top;
}

.content {
    background-image: url("#{$baseurl}/images/fondo.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 1;

    &--padding-top {
        padding-top: $nav-height;
    }
}

.header > .wrapper {
    width: 100%;
}

.header--dark {
    background-color: rgba(57, 59, 57, 0.85);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;

    .menu__title {
        color: white;
    }
}

.wrapper {
    @include outer-container;

    background-color: white;
}

.wrapper--transparent {
    background-color: transparent;
}

.wrapper--page {
    @include calc(min-height, "100vh - #{$nav-height} - #{$copy-height}");
}

.logo {
    @include media(max-width rem(750), 4) {
        margin: rem(15);
    }
    background-image: url("#{$baseurl}/images/logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    width: $logo-width;
    height: $logo-height;
}

.nav {
    @include display(flex);
    @include justify-content(space-between);
    @include align-items(center);

    min-height: $nav-height;
}

.search-box {
    @include media(max-width rem(750), 4) {
        min-height: $logo-height * 2;
    }

    @include media(min-width rem(750), 12) {
        min-height: $search-box-height;
    }

    background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0) $search-box-height * 0.3
        ),
        url("#{$baseurl}/images/sea-418742_1920.jpg") center center / cover;
    color: white;

    @include display(flex);
    @include flex-direction(column);
}

.wrapper--new-quotes {
    @include flex-grow(1);

    @include display(flex);
    @include flex-direction(column);

    background-color: rgba(255, 255, 255, 0.35);
    border: 2px solid white;
    border-radius: 1rem;
    padding: 1rem;
    margin: 3rem auto;
    box-shadow: 0 0 2px black;
}

.new-quotes {
    @include flex-grow(1);

    @include display(flex);
    @include flex-direction(column);
    @include justify-content(space-between);

    text-align: center;

    &__text {
        @include flex-grow(1);
        padding-top: 2rem;
        font-size: 1.5rem;
        text-shadow: 0 0 2px black;
    }

    &__button {
        font-size: 1.5rem;
        background-color: green;
        border: 2px solid white;
        border-radius: 1.5rem;
        color: white;
        padding: 0.5rem 2rem;
        box-shadow: 0 0 2px black;
        margin: 0 3rem;
        text-decoration: none;

        &:hover {
            background-color: lighten(green, 5);
            text-decoration: none;
        }

        &:active {
            background-color: darken(green, 5);
            text-decoration: none;
        }
    }
}

.featured__item {
    @include media(max-width rem(750), 4) {
        border-width: rem(25);
    }

    @include media(min-width rem(750), 12) {
        @include omega(3n);
        @include span-columns(4);

        border-bottom-width: rem(25);
        border-color: white;
        border-style: solid;
        border-top-width: rem(25);

        &:nth-of-type(3n + 1) {
            border-left-width: rem(25);
        }

        &:nth-of-type(3n) {
            border-right-width: rem(25);
        }
    }

    background-position: center rem(50);
    background-repeat: no-repeat;
    background-size: cover;
    border-color: white;
    border-style: solid;
    height: rem(300);
    overflow: hidden;

    a {
        color: black;
    }
}

.featured__item-head {
    background-color: rgb(197, 205, 215);
    height: rem(50);
    padding: rem(5);
    text-align: center;
}

.featured__item-subtitle {
    font-size: 0.85em;
}

.featured__item-description {
    @include media(min-width rem(750), 12) {
        @include transition(top 250ms ease-in-out);
        top: rem(200);
    }

    background-color: rgba(255, 255, 255, 0.85);
    height: rem(200);
    padding: rem(10);
    position: relative;
}

@include media(min-width rem(750), 12) {
    .featured__item:hover {
        .featured__item-description {
            top: rem(50);
        }
    }
}

.featured__item--viajes {
    background-image: url("#{$baseurl}/images/viajes.jpg");
}

.featured__item--tours {
    background-image: url("#{$baseurl}/images/tour.jpg");
}

.featured__item--visas {
    background-image: url("#{$baseurl}/images/travel-visa-glasses.jpg");
}

.featured__item--circuitos {
    background-image: url("#{$baseurl}/images/mochilazo.jpg");
}

.footer {
    @include display(flex);
    @include align-items(flex-end);

    background-color: rgb(79, 82, 80);
    bottom: $copy-height;
    color: white;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 0;

    .wrapper {
        @include flex-grow(1);
        margin-bottom: rem(16);
    }
}

.copy {
    @include display(flex);
    @include align-items(center);

    background-color: rgb(57, 59, 57);
    bottom: rem(0);
    color: white;
    min-height: $copy-height;
    position: fixed;
    width: 100%;
    z-index: 0;

    .wrapper {
        @include flex-grow(1);
    }
}

.page__title {
    padding: rem(20);
}

.page__content {
    padding: rem(20);
}

.page__content--tours,
.page__content--promotions {
    h2,
    h3 {
        margin: rem(20) 0;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    th,
    td {
        padding: 0.25rem;
        text-align: left;
        border: 1px solid #ccc;
    }

    tbody tr:nth-child(odd) {
        background: #eee;
    }
}

.page__heading-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: rem(300);
}

.page__heading-image--viajes {
    background-image: url("#{$baseurl}/images/people-in-beach.jpg");
    background-position: center bottom;
}

.page__heading-image--renta {
    background-image: url("#{$baseurl}/images/people-feet-train-travelling.jpg");
}

.page__heading-image--igualamos {
    background-image: url("#{$baseurl}/images/travel-jar.jpg");
}

.page__heading-image--ubicacion {
    background-image: url("#{$baseurl}/images/hands-way-guide-tourist.jpg");
}

.page__heading-image--gracias {
    background-image: url("#{$baseurl}/images/working.jpg");
}

.page__heading-image--tours {
    background-image: url("#{$baseurl}/images/tours.jpg");
}

.page__heading-image--bcs {
    background-image: url("#{$baseurl}/images/bcs.jpg");
    background-position: center bottom;
}

.page__heading-image--jalisco {
    background-image: url("#{$baseurl}/images/jalisco.jpg");
}

.page__heading-image--slp {
    background-image: url("#{$baseurl}/images/slp.jpg");
    background-position: center bottom;
}

.page__heading-image--sonora {
    background-image: url("#{$baseurl}/images/sonora.jpg");
    background-position: center 65%;
}

.page__heading-image--tamaulipas {
    background-image: url("#{$baseurl}/images/tamaulipas.jpg");
    background-position: center 65%;
}

.page__heading-image--cdmx {
    background-image: url("#{$baseurl}/images/cdmx.jpg");
    background-position: center 40%;
}

.page__heading-image--puebla {
    background-image: url("#{$baseurl}/images/puebla.jpg");
    background-position: center 80%;
}

.page__heading-image--qroo {
    background-image: url("#{$baseurl}/images/qroo.jpg");
}

.page__heading-image--veracruz {
    background-image: url("#{$baseurl}/images/veracruz.jpg");
}

.page__heading-image--contacto {
    background-image: url("#{$baseurl}/images/coffee-hands-mobile-phone.jpg");
}

.page__heading-image--visas {
    background-image: url("#{$baseurl}/images/travel-visa-glasses.jpg");
}

.sections {
    background-color: rgb(236, 237, 238);
}

.sections__item {
    border-color: rgb(236, 237, 238);
    border-style: solid;
    text-align: center;

    a {
        color: black;
    }

    @include media(max-width rem(750), 4) {
        border-width: rem(25);
    }

    @include media(min-width rem(750), 12) {
        @include omega(4n);
        @include span-columns(3);

        border-bottom-width: rem(25);
        border-top-width: rem(25);
        height: rem(325);

        &:nth-of-type(4n + 1) {
            border-left-width: rem(25);
        }

        &:nth-of-type(4n) {
            border-right-width: rem(25);
        }
    }
}

.sections__item-description {
    font-size: 0.8em;
}

.sections__item-picture {
    width: 100%;
    height: rem(150);
    background-size: rem(150);
    background-position: center center;
    background-repeat: no-repeat;
}

.sections__item-picture--blogs {
    background-image: url("#{$baseurl}/images/blog.png");
}

.sections__item-picture--renta {
    background-image: url("#{$baseurl}/images/bus.png");
}

.sections__item-picture--ubicacion {
    background-image: url("#{$baseurl}/images/ubicacion.png");
}

.sections__item-picture--igualamos {
    background-image: url("#{$baseurl}/images/precio.png");
}

.sections__item-title,
.sections__item-description {
    padding-top: rem(10);
}

.promotions {
    background-color: rgb(236, 237, 238);
    padding: rem(25) rem(50);

    &__title {
        background-color: rgb(236, 237, 238);
        font-size: 1.5em;
        font-weight: 200;
        padding-top: rem(20);
        text-align: center;
    }

    &__item {
        text-align: center;
    }

    &__item-title,
    &__item-title:visited {
        color: black;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.slick-prev::before,
.slick-next::before {
    color: rgb(79, 82, 80);
}

.slick--renta {
    @include span-columns(10);
    @include shift(1);
    @include omega();
    padding: rem(50) 0;
}

.contact-form {
    @include fill-parent;

    &__title {
        @include pad(rem(50) 0);
    }

    &__row {
        @include row();
    }

    &__label {
        @include span-columns(3);
        text-align: right;
        vertical-align: top;
        font-weight: bold;
    }

    &__field {
        @include span-columns(8);
        vertical-align: top;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea {
            width: 100%;
        }
    }

    &__error {
        @include pad(em(5) 0 rem(15));
        font-size: 0.8em;
        color: red;
    }

    &__enviar {
        @include shift(4);
        @include span-columns(4);
        @include linear-gradient(rgb(0, 190, 0), rgb(0, 130, 0));
        border: 1px solid rgb(0, 130, 0);
        border-radius: em(15);
        min-height: em(50);
        font-weight: bold;
        color: white;
    }
}

.ubicacion {
    &__mapa {
        @include span-columns(12);
        @include pad(rem(50) 0 rem(50) 0);
        border: 0;
        min-height: rem(500);
    }
}

.menu {
    position: relative;

    &__title {
        @include user-select(none);
        background-color: rgb(57, 59, 57);
        border-radius: rem(10) rem(10) 0 0;
        cursor: pointer;
        padding: rem(10);

        &--hide {
            color: rgb(47, 137, 201);
            background-color: transparent;
        }
    }

    &__list {
        background-color: rgb(57, 59, 57);
        border-radius: rem(10) 0 rem(10) rem(10);
        list-style: none;
        margin: 0;
        min-width: rem(280);
        padding-left: 0;
        position: absolute;
        right: 0;
        text-align: right;
        z-index: 1;

        &--hide {
            display: none;
            visibility: hidden;
        }
    }

    &__item {
        padding: rem(5) rem(15) rem(5) rem(5);

        &:hover {
            background-color: lighten(rgb(57, 59, 57), 10%);

            &:first-of-type {
                border-radius: rem(10) 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 rem(10) rem(10);
            }
        }

        &:first-of-type {
            padding-top: rem(10);
        }

        &:last-of-type {
            padding-bottom: rem(10);
        }

        a,
        a:hover,
        a:active {
            color: white;
            display: inline-block;
            width: 100%;
        }
    }
}

.footer__item {
    @include omega(2n);
    @include span-columns(6);

    padding: rem(15);
}

@media screen and (max-width: 700px) {
    .footer__item:nth-of-type(2n-1) {
        display: none;
    }
    .footer__item:nth-of-type(2n) {
        width: 100%;
    }
}

.footer-menu__list,
.social__list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.social__item--number {
    display: inline-block;
    min-width: 175px;
}

.footer {
    .footer-menu__list,
    .social__list {
        a {
            color: white;
        }
    }
}

// Parsley

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    border: 1px solid red;
}

.banner {
    &__image {
        min-height: rem(88);
        background-image: url("#{$baseurl}/images/banners/horizontal-largo.jpg");
        background-position: center;
    }
}
