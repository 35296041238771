.circuitos__sidebar {
  // @include span-columns(2);
}

.circuitos__content {
  @include span-columns(12);
}

.circuitos__links {
  list-style: none;
  padding-left: 0;
}

.circuitos__frame {
  @include span-columns(10 of 10);
  min-height: 1200px;
}
